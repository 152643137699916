import React from 'react';
import Layout from '../components/Layout';
import Callout from '../components/Callout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Image from '../components/Image';
import Button from '../components/Button';
import Carousel from '../components/Carousel';
import Hero from '../components/Hero';
import FooterCallout from './partials/_FooterCallout';
import Girls4TechCallout from './partials/_Girls4TechCallout';
import CareerCallout from './partials/_CareerCallout';
import HeroImage from '../images/hero/hero-home.png';
import { useTranslation } from 'react-i18next';

const IndexPage = ({ pageContext }) => {
  const { t, i18n } = useTranslation(['home', 'career-profiles', 'common']);
  return (
    <Layout title="Home">
      <Hero title={t('heroText')} image={HeroImage} isHome />
      <Callout
        to={`/${i18n.language}/video-topic-series`}
        className="home__callout"
      >
        {t('callout')}
      </Callout>
      <Section>
        <Container>
          <Row>
            <Column medium={6} large={5}>
              <Image filename="home-about.jpg" />
            </Column>
            <Column medium={6} large={7}>
              <h2>{t('exploreFrontiersHeader')}</h2>
              <p
                dangerouslySetInnerHTML={{ __html: t('exploreFrontiersText') }}
              />
              <Button to={`/${i18n.language}/about`}>
                {t('common:aboutTheProgram')}
              </Button>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <div className="home__careers">
            <Row>
              <Column
                xsmall={9}
                xsmallOffset={3}
                medium={3}
                mediumOffset={1}
                className="home__careers-img"
              >
                <Image filename="home-careers.jpg" />
              </Column>
              <Column medium={8} large={7}>
                <h2>{t('helpCyberStudentsHeader')}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('helpCyberStudentsText'),
                  }}
                />
                <Button to={`/${i18n.language}/career-profiles`}>
                  {t('helpCyberStudentsButtonText')}
                </Button>
              </Column>
            </Row>
          </div>
        </Container>
      </Section>
      <div className="home__carousel-container">
        <Section>
          <Container>
            <Row withDividers>
              <Column size={5} offset={1}>
                <Carousel
                  settings={{
                    dots: true,
                  }}
                  cards={[
                    {
                      image: 'home-all-things-digital.svg',
                      title: t('common:allThingsDigital'),
                      text: t('carouselDigitalText'),
                    },
                    {
                      image: 'home-cryptology.svg',
                      title: t('common:cryptology'),
                      text: t('carouselCryptologyText'),
                    },
                    {
                      image: 'home-fraud-detection.svg',
                      title: t('common:fraudDetection'),
                      text: t('carouselFraudText'),
                    },
                    {
                      image: 'home-internet-of-things.svg',
                      title: t('common:bigData'),
                      text: t('carouselBigDataText'),
                    },
                    {
                      image: 'home-ai-social-good.svg',
                      title: t('common:aiForGood'),
                      text: t('carouselAIText'),
                    },
                  ]}
                />
              </Column>
              <Column
                size={5}
                offset={1}
                className="home__explore-cybersecurity"
              >
                <h2>{t('exploreCybersecurityHeader')}</h2>
                <p>{t('exploreCybersecurityText')}</p>
                <Button to={`/${i18n.language}/cybersecurity`}>
                  {t('exploreCybersecurityButtonText')}
                </Button>
              </Column>
            </Row>
          </Container>
        </Section>
      </div>
      <CareerCallout
        title={t('footerCareerCalloutHeader')}
        description={
          <p
            dangerouslySetInnerHTML={{
              __html: t('footerCareerCalloutBody'),
            }}
          />
        }
        path={`/${i18n.language}${t('footerCareerCalloutURL')}`}
        img={t('career-profiles:dataScienceManagerQuoteImage')}
        name={t('footerCareerCalloutName')}
        job={t('footerCareerCalloutJob')}
      />
      <FooterCallout text={t('subfooterCallout')} className="home__iot-fact" />
      <Girls4TechCallout />
    </Layout>
  );
};

export default IndexPage;
